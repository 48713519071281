import PropTypes from "prop-types";
import React from "react";
import "./image.scss";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSource: null
    };
    this.setDisplayImage = this.setDisplayImage.bind(this);
    this.handleInitialTimeout = this.handleInitialTimeout.bind(this);
    this.isLoaded = false;
  }

  handleInitialTimeout() {
    if (this.props.initialTimeout && this.props.initialTimeout > 0) {
      setTimeout(() => {
        if (!this.isLoaded) {
          this.setState({
            imageSource: this.props.initialImage
          });
        }
      }, this.props.initialTimeout);
    } else {
      this.setState({
        imageSource: this.props.initialImage
      });
    }
  }

  componentDidMount() {
    this.handleInitialTimeout();
    this.displayImage = new window.Image();
    this.setDisplayImage({
      image: this.props.src,
      fallbacks: this.props.fallbackImage
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.isLoaded = false;
      if (this.props.initialImage) {
        this.handleInitialTimeout();
      }
      this.setDisplayImage({
        image: this.props.src,
        fallbacks: this.props.fallbackImage
      });
    }
  }

  componentWillUnmount() {
    if (this.displayImage) {
      this.displayImage.onerror = null;
      this.displayImage.onload = null;
      this.displayImage = null;
    }
  }

  setDisplayImage({ image, fallbacks }) {
    const imagesArray = [image].concat(fallbacks).filter(fallback => !!fallback);
    this.displayImage.onerror = () => {
      if (imagesArray.length > 2 && typeof imagesArray[1] === "string") {
        const updatedFallbacks = imagesArray.slice(2);
        this.setDisplayImage({
          image: imagesArray[1],
          fallbacks: updatedFallbacks
        });
        return;
      }
      this.isLoaded = true;
      this.setState(
        {
          imageSource: imagesArray[1] || null
        },
        () => {
          if (this.props.onError) {
            this.props.onError(this.props.src);
          }
        }
      );
    };
    this.displayImage.onload = () => {
      this.isLoaded = true;
      this.setState(
        {
          imageSource: imagesArray[0]
        },
        () => {
          if (this.props.onLoad) {
            this.props.onLoad(imagesArray[0]);
          }
        }
      );
    };
    if (typeof imagesArray[0] === "string") {
      this.displayImage.src = imagesArray[0];
    } else {
      this.setState(
        {
          imageSource: imagesArray[0]
        },
        () => {
          if (this.props.onLoad) {
            this.props.onLoad(imagesArray[0]);
          }
        }
      );
    }
  }

  render() {
    return typeof this.state.imageSource === "string" ? (
      <img className={`image${this.props.className ? " " + this.props.className : ""}`} alt={this.props.alt} src={this.state.imageSource} />
    ) : (
      this.state.imageSource
    );
  }
}

Image.displayName = "Image";

Image.propTypes = {
  /** Image to be used */
  src: PropTypes.string,
  /** Fallback image to be used if main image fails */
  fallbackImage: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
  /** Initial image to be used while waiting for main image to be loaded */
  initialImage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Function hook for image on load */
  onLoad: PropTypes.func,
  /** Function hook for image on error */
  onError: PropTypes.func,
  /** Timeout to wait before showing main image */
  initialTimeout: PropTypes.number
};

Image.defaultProps = {
  initialImage: null
};

export default Image;
