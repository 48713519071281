import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./grid.scss";

const Grid = ({ children, className }) => {
  return <div className={classnames(className, "grid")}>{children}</div>;
};

Grid.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Custom class name for grid */
  className: PropTypes.string
};

export default Grid;
