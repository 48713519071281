import "babel-polyfill";
import "react-app-polyfill/ie9";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Routes } from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";

ReactDOM.render(<Routes />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
smoothscroll.polyfill();
