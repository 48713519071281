import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import lazyRoute from "./higherOrderedComponents/lazyRoute";

// Pages
const HomePage = React.lazy(() => import("./pages/HomePage"));
const EventsPage = React.lazy(() => import("./pages/EventsPage"));

export const Routes = () => {
  return (
    <BrowserRouter>
      <App>
        <Switch>
          {routeList.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Switch>
      </App>
    </BrowserRouter>
  );
};

const routeList = [
  {
    path: "/",
    component: lazyRoute(HomePage),
    exact: true
  },
  {
    path: "/events",
    component: lazyRoute(EventsPage),
    exact: true
  }
];
