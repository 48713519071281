import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./blocks.scss";

const absolutePositionMap = {
  "top-left": "top-left",
  "top-right": "top-right",
  "bottom-left": "bottom-left",
  "bottom-right": "bottom-right"
};

const Blocks = ({ children, className, style, absolutePosition, hideOnMobile, hideOnDesktop }) => {
  return (
    <div
      style={style}
      className={classnames(className, "blocks", {
        [`blocks--${absolutePositionMap[absolutePosition]}`]: !!absolutePositionMap[absolutePosition],
        "block--hide-on-mobile": hideOnMobile,
        "block--hide-on-desktop": hideOnDesktop
      })}
    >
      {children}
    </div>
  );
};

Blocks.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Custom class name for content wrapper */
  className: PropTypes.string,
  /** Event column span */
  style: PropTypes.object,
  /** Absolute position for component */
  absolutePosition: PropTypes.oneOf(Object.values(absolutePositionMap)),
  /** Hide component on mobile screens */
  hideOnMobile: PropTypes.bool,
  /** Hide component on desktop screens */
  hideOnDesktop: PropTypes.bool
};

export default Blocks;
