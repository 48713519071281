import React from "react";
import { SuspenseContent, Loader } from "../components";

const lazyRoute = Component => {
  return class lazyRoute extends React.PureComponent {
    render() {
      return (
        <SuspenseContent fallback={<Loader fullScreen />}>
          <Component {...this.props} />
        </SuspenseContent>
      );
    }
  };
};

export default lazyRoute;
