import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Image from "../../../Image";
import "./event.scss";
import { ReactComponent as RightArrow } from "../../../../assets/svgs/right-arrow.svg";

const Event = ({ title, arenaCode, duration, ingress, to, image, imageText, className }) => {
  return (
    <div className={classnames(className, "event")}>
      <div className="event__image-container">
        <figure className="event__figure">
          <div className="event__image-wrapper">
            <Image
              className="event__image"
              src={`/api/image?url=${image}`}
              alt="img"
              initialImage="/content/images/image-placeholder.jpg"
              fallbackImage="/content/images/image-placeholder.jpg"
            />
            <div className="event__image-text">{imageText}</div>
          </div>
        </figure>
      </div>
      <div className="event__details">
        <div className="event__title">{title}</div>
        <div className="event__arena-code">{arenaCode}</div>
        <div className="event__duration" dangerouslySetInnerHTML={{ __html: duration }} />
        <div className="event__ingress" dangerouslySetInnerHTML={{ __html: ingress }} />
        <div className="event__link-container">
          <a className="event__link" href={to || "https://fib.no/program/"} target="_blank" rel="noopener noreferrer">
            <span className="event__link-text">Les mer</span>
            <span className="event__link-icon">
              <RightArrow />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

Event.propTypes = {
  /** Title for the component */
  title: PropTypes.string,
  /** Arena code for the component */
  arenaCode: PropTypes.string,
  /** Duration text for the component */
  duration: PropTypes.string,
  /** Ingress text for the component */
  ingress: PropTypes.string,
  /** Link to buy ticket for the component */
  to: PropTypes.string,
  /** Image for the component */
  image: PropTypes.string,
  /** Image text for the component */
  imageText: PropTypes.string,
  /** Custom class name for component */
  className: PropTypes.string
};

export default Event;
