import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/svgs/logo-white.svg";
import { ReactComponent as DownArrow } from "../../../../assets/svgs/down-arrow.svg";
import ContentWrapper from "../../../ContentWrapper";
import "./header.scss";
import { renderHeaderTitleBlocks, renderHeaderBlocks } from "../../../../factories/blocksFactory";

const Header = ({ className, onScrollClick, ended }) => {
  return (
    <header
      className={classnames(className, "header", {
        "header--ended": ended
      })}
    >
      <ContentWrapper className="header__content-wrapper" noPadding>
        <div className="header__logo-container">
          <div className="header__logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
        <div className="header__title">
          <h1>Festivalgomaten</h1>
          {renderHeaderTitleBlocks()}
        </div>
        <div className="header__text">
          Vanskelig å velge fra festspillprogrammet? La vår uhøytidelige festivalgomat hjelpe deg frem til opplevelsene du bør få med deg under årets festspill.
        </div>
      </ContentWrapper>
      {onScrollClick && (
        <div className="header__scroll-container">
          <div className="header__scroll" onClick={() => onScrollClick()}>
            <DownArrow />
          </div>
        </div>
      )}
      <ContentWrapper className="header__bottom-blocks" noPadding>
        {renderHeaderBlocks()}
      </ContentWrapper>
    </header>
  );
};

Header.propTypes = {
  /** Custom class name for header */
  className: PropTypes.string,
  /** On scroll click */
  onScrollClick: PropTypes.func,
  /** Ended */
  ended: PropTypes.func
};

export default Header;
