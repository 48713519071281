import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./block.scss";

const backgroundColorMap = {
  white: "white",
  black: "black",
  blue: "blue",
  yellow: "yellow",
  red: "red",
  "blue-darken": "blue-darken",
  "yellow-darken": "yellow-darken",
  "red-darken": "red-darken"
};

const animationMap = {
  "slide-right": "slide-right",
  "slide-left": "slide-left",
  "slide-top": "slide-top",
  "slide-bottom": "slide-bottom"
};

const Block = ({ className, style, backgroundColor, animation, delay = 1, hideOnMobile }) => {
  return (
    <span
      style={style}
      className={classnames(className, "block", `block--delay-${delay}`, {
        [`block--${backgroundColorMap[backgroundColor]}`]: !!backgroundColorMap[backgroundColor],
        [`block--${animationMap[animation]}`]: !!animationMap[animation],
        "block--hide-on-mobile": hideOnMobile
      })}
    />
  );
};

Block.propTypes = {
  /** Custom class name for content wrapper */
  className: PropTypes.string,
  /** Event column span */
  style: PropTypes.object,
  /** Background color for component */
  backgroundColor: PropTypes.oneOf(Object.values(backgroundColorMap)),
  /** Animation for component */
  animation: PropTypes.oneOf(Object.values(animationMap)),
  /** Animation delay for component */
  delay: PropTypes.number,
  /** Hide component on mobile screens */
  hideOnMobile: PropTypes.bool
};

export default Block;
