import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./loader.scss";

const Loader = ({ fullScreen }) => {
  return (
    <div
      className={classnames("loader", {
        "loader--fullscreen": fullScreen
      })}
    >
      <div className="loader__content">
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
        <div className="loader__bar" />
      </div>
    </div>
  );
};

Loader.propTypes = {
  /** Loader should cover screen */
  fullScreen: PropTypes.bool
};

export default Loader;
