import PropTypes from "prop-types";
import React from "react";

const SuspenseContent = ({ children, fallback }) => {
  return <React.Suspense fallback={fallback || <div>Is loading...</div>}>{children}</React.Suspense>;
};

SuspenseContent.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Custom fallback option */
  fallback: PropTypes.node
};

export default SuspenseContent;
