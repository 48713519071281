import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Grid, { GridItem } from "../../../Grid";
import Event from "../Event";
import "./eventList.scss";

const EventList = ({ events, className, eventSpan = 3 }) => {
  return (
    <Grid className={classnames(className, "event-list")}>
      {events.map((event, index) => (
        <GridItem key={index} span={eventSpan}>
          <Event
            title={event.title}
            arenaCode={event.arenaCode}
            duration={event.duration}
            ingress={event.ingress}
            to={event.url}
            image={event.image}
            imageText={event.imageText}
          />
        </GridItem>
      ))}
    </Grid>
  );
};

EventList.propTypes = {
  /** Events for the component */
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Custom class name for content wrapper */
  className: PropTypes.string,
  /** Event column span */
  eventSpan: PropTypes.number
};

export default EventList;
