import React from "react";
import { Blocks, Block } from "../components";

export const renderHeaderTitleBlocks = () => {
  return (
    <React.Fragment>
      <Blocks className="header__title-blocks" absolutePosition="bottom-left" hideOnMobile>
        <Block
          className="header__title-block"
          backgroundColor="yellow-darken"
          animation="slide-left"
          delay={1}
          style={{ bottom: 0, left: "-25rem", width: "6.8rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="red-darken"
          animation="slide-left"
          delay={2}
          style={{ bottom: "-4.8rem", left: "-21.6rem", width: "6.8rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="yellow-darken"
          animation="slide-left"
          delay={3}
          style={{ bottom: "-2.4rem", left: "-14.8rem", width: "14.8rem" }}
        />
        <Block className="header__title-block" backgroundColor="blue-darken" animation="slide-left" delay={4} style={{ bottom: "-7.2rem", left: "-10rem" }} />
        <Block
          className="header__title-block"
          backgroundColor="red-darken"
          animation="slide-left"
          delay={5}
          style={{ bottom: "-4.8rem", left: "0" }}
          hideOnMobile
        />
        <Block
          className="header__title-block"
          backgroundColor="red-darken"
          animation="slide-left"
          delay={6}
          style={{ bottom: 0, left: 0, width: "6.8rem" }}
          hideOnMobile
        />
        <Block
          className="header__title-block"
          backgroundColor="blue-darken"
          animation="slide-left"
          delay={7}
          style={{ bottom: "-2.4rem", left: "6.8rem", width: "6.8rem" }}
          hideOnMobile
        />
      </Blocks>
      <Blocks className="header__title-blocks" absolutePosition="bottom-right" hideOnMobile>
        <Block className="header__title-block" backgroundColor="blue-darken" animation="slide-right" delay={1} style={{ bottom: 0, left: "-29rem" }} />
        <Block
          className="header__title-block"
          backgroundColor="red-darken"
          animation="slide-right"
          delay={2}
          style={{ bottom: "-2.4rem", left: "-26.6rem", width: "4.6rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="yellow-darken"
          animation="slide-right"
          delay={3}
          style={{ bottom: 0, left: "-22rem", width: "17.9rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={8}
          style={{ bottom: "-2.4rem", left: "-4.1rem", width: "6.8rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="yellow-darken"
          animation="slide-right"
          delay={9}
          style={{ bottom: "-4.8rem", left: "2.7rem", width: "6.8rem" }}
        />
        <Block className="header__title-block" backgroundColor="red-darken" animation="slide-right" delay={10} style={{ bottom: 0, left: "7.1rem" }} />
        <Block
          className="header__title-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={11}
          style={{ bottom: "2.4rem", left: "15rem", width: "6.8rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="red-darken"
          animation="slide-right"
          delay={12}
          style={{ bottom: "-9.6rem", left: "12rem", width: "14.8rem" }}
        />
      </Blocks>
      {/* Mobile blocks */}
      <Blocks className="header__title-blocks" absolutePosition="bottom-right" hideOnDesktop>
        <Block
          className="header__title-block"
          backgroundColor="yellow-darken"
          animation="slide-right"
          delay={1}
          style={{ bottom: 0, right: "13.8rem", width: "14.8rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={2}
          style={{ bottom: "-2.4rem", right: "6.8rem", width: "6.8rem" }}
        />
        <Block
          className="header__title-block"
          backgroundColor="yellow-darken"
          animation="slide-right"
          delay={3}
          style={{ bottom: "-4.8rem", right: 0, width: "6.8rem" }}
        />
        <Block className="header__title-block" backgroundColor="red-darken" animation="slide-right" delay={4} style={{ bottom: 0, right: 0 }} />
      </Blocks>
    </React.Fragment>
  );
};

export const renderHeaderBlocks = () => {
  return (
    <Blocks className="header__blocks" style={{ left: "10rem" }} hideOnMobile>
      <Block
        className="header__block"
        backgroundColor="blue-darken"
        animation="slide-left"
        delay={13}
        style={{ bottom: "4.8rem", left: "16rem", width: "6.8rem" }}
      />
      <Block
        className="header__block"
        backgroundColor="yellow-darken"
        animation="slide-left"
        delay={14}
        style={{ bottom: "-2.4rem", left: "13.2rem", width: "6.8rem" }}
      />
      <Block className="header__block" backgroundColor="red-darken" animation="slide-left" delay={15} style={{ bottom: 0, width: "14.8rem", left: "20rem" }} />
      <Block
        className="header__block"
        backgroundColor="red-darken"
        animation="slide-left"
        delay={16}
        style={{ bottom: "-7.2rem", left: "10rem", width: "6.8rem" }}
      />
    </Blocks>
  );
};

export const renderQuestionResultHeaderBlocks = () => {
  return (
    <React.Fragment>
      <Blocks className="question-result__header-blocks" absolutePosition="bottom-right" hideOnMobile>
        <Block
          className="question-result__header-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={1}
          style={{ bottom: "4.8rem", left: "-6rem", width: "6rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="red-darken"
          animation="slide-right"
          delay={2}
          style={{ bottom: "2.4rem", left: "0", width: "14rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="yellow-darken"
          animation="slide-right"
          delay={3}
          style={{ bottom: "0", left: "18rem", width: "6rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="red-darken"
          animation="slide-right"
          delay={4}
          style={{ bottom: "-2.4rem", left: "24rem", width: "5rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={5}
          style={{ bottom: "-4.8rem", left: "29rem", width: "3rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={6}
          style={{ bottom: "4.8rem", left: "29rem", width: "6rem" }}
        />
      </Blocks>
      <Blocks className="question-result__header-blocks" absolutePosition="bottom-right" hideOnDesktop>
        <Block
          className="question-result__header-block"
          backgroundColor="yellow-darken"
          animation="slide-right"
          delay={1}
          style={{ bottom: "0", right: "1rem", width: "10.4rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="blue-darken"
          animation="slide-right"
          delay={2}
          style={{ bottom: "-2.4rem", right: "-4.4rem", width: "5.4rem" }}
        />
        <Block
          className="question-result__header-block"
          backgroundColor="red-darken"
          animation="slide-right"
          delay={3}
          style={{ bottom: "2.8rem", right: "-6.8rem" }}
        />
      </Blocks>
    </React.Fragment>
  );
};

export const renderQuestionResultShareBlocks = () => {
  return (
    <Blocks className="question-result__share-blocks" style={{ bottom: "30rem", left: 0 }} hideOnMobile>
      <Block
        className="question-result__share-block"
        backgroundColor="blue-darken"
        animation="slide-left"
        delay={1}
        style={{ bottom: "-2.4rem", left: "12rem", width: "16rem" }}
      />
      <Block
        className="question-result__share-block"
        backgroundColor="yellow-darken"
        animation="slide-left"
        delay={2}
        style={{ bottom: "5.2rem", left: "19rem" }}
      />
      <Block
        className="question-result__share-block"
        backgroundColor="red-darken"
        animation="slide-left"
        delay={3}
        style={{ bottom: "2.4rem", left: "9rem", width: "8rem" }}
      />
      <Block
        className="question-result__share-block"
        backgroundColor="yellow-darken"
        animation="slide-left"
        delay={4}
        style={{ bottom: "-4.8rem", width: "6rem", left: "6rem" }}
      />
      <Block
        className="question-result__share-block"
        backgroundColor="red-darken"
        animation="slide-left"
        delay={5}
        style={{ bottom: "-2.4rem", left: "3rem", width: "3rem" }}
      />
      <Block
        className="question-result__share-block"
        backgroundColor="blue-darken"
        animation="slide-left"
        delay={6}
        style={{ bottom: "-7.2rem", left: "-2rem", width: "5rem" }}
      />
    </Blocks>
  );
};
