import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./footer.scss";
import ContentWrapper from "../../../ContentWrapper";
import { ReactComponent as DnbLogo } from "../../../../assets/svgs/dnb-logo.svg";

const Footer = ({ className, ended }) => {
  return (
    <footer
      className={classnames(className, "footer", {
        "footer--ended": ended
      })}
    >
      <ContentWrapper className="footer__content-wrapper">
        <div className="footer__register-link-container">
          <a className="footer__register-link" href="mailto:marked.bergen@dnb.no?subject=Jeg ønsker å vinne billetter til Festspillene!">
            Vinn billetter til Festspillene ved å registrere deg her!
          </a>
        </div>
        <div className="footer__sponsor">
          <div className="footer__sponsor-text">Festivalgomaten er utviklet i samarbeid med vår hovedpartner</div>
          <div className="footer__sponsor-logo-container">
            <div className="footer__sponsor-logo">
              <a href="https://www.dnb.no/en">
                <DnbLogo />
              </a>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </footer>
  );
};

Footer.propTypes = {
  /** Custom class name for footer */
  className: PropTypes.string,
  /** Ended */
  ended: PropTypes.func
};

export default Footer;
