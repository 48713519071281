import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./gridItem.scss";

const GridItem = ({ children, className, hideOnMobile, span, spanPhone }) => {
  return (
    <div
      className={classnames(className, "grid-item", `grid-item--span-${span}`, {
        "grid-item--hide-on-mobile": hideOnMobile,
        [`grid-item--span-${span}`]: span,
        [`grid-item--span-phone-${spanPhone}`]: spanPhone
      })}
    >
      {children}
    </div>
  );
};

GridItem.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Custom class name for grid item */
  className: PropTypes.string,
  /** Hide grid item on mobile screens */
  hideOnMobile: PropTypes.bool,
  /** How many columns should the item span */
  span: PropTypes.number,
  /** How many columns should the item span on phone */
  spanPhone: PropTypes.number
};

export default GridItem;
