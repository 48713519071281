import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./contentWrapper.scss";

const backgroundColorMapping = {
  white: "white",
  black: "black",
  red: "red",
  yellow: "yellow",
  blue: "blue"
};

const ContentWrapper = ({ children, style, contentWrapperRef, noPadding, backgroundColor, fullWidth, className }) => {
  return (
    <div
      style={style}
      className={classnames(className, "content-wrapper", {
        "content-wrapper--no-padding": noPadding,
        [`content-wrapper--${backgroundColorMapping[backgroundColor]}`]: !!backgroundColorMapping[backgroundColor],
        "content-wrapper--full-width": fullWidth
      })}
      ref={contentWrapperRef}
    >
      {children}
    </div>
  );
};

ContentWrapper.propTypes = {
  /** Children for the component */
  children: PropTypes.node.isRequired,
  /** Event column span */
  style: PropTypes.object,
  /** Ref for content wrapper */
  contentWrapperRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** No padding for the component */
  noPadding: PropTypes.bool,
  /** Background color for component */
  backgroundColor: PropTypes.oneOf(Object.values(backgroundColorMapping)),
  /** Should the content wrapper be full width */
  fullWidth: PropTypes.bool,
  /** Custom class name for content wrapper */
  className: PropTypes.string
};

export default ContentWrapper;
